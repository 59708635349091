:root {
  /** Font */
  --vui-font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --vui-font-weight-regular: 400;
  --vui-font-weight-medium: 500;
  --vui-font-weight-semi-bold: 600;
  --vui-font-weight-bold: 600;

  /** Font size */
  --vui-font-size-base: 16px;

  /** Line height */
  --vui-line-height-base: 1.5;

  /** Spacer */
  --vui-spacer-12: 0.75rem;
  --vui-spacer-16: 1rem;
  --vui-spacer-24: 1.5rem;
  --vui-spacer-32: 2rem;

  /** Radius */
  --vui-radius-full: 9999px;

  /** Color */
  --vui-color-black: oklch(0% 0 0);
  --vui-color-white: oklch(100% 3.5594404384177905e-8 106.37411429114086);
  --vui-color-primary: oklch(82.75% 0.0856919193561856 262.68127551846214);
  --vui-color-on-primary: oklch(31.22% 0.0941 255.65);
  --vui-color-primary-container: oklch(39.86% 0.0899 258.33);
  --vui-color-on-primary-container: oklch(91.47% 0.0408 265.71);
  --vui-color-secondary-container: oklch(39.67% 0.0312 263.06);
  --vui-color-on-secondary-container: oklch(91.37% 0.0325 270.44);
  --vui-color-surface: oklch(18.69% 0.0107 268.11);
  --vui-color-on-surface: oklch(91.48% 0.0094 286.21);
  --vui-color-surface-container: oklch(24.21% 0.0089 255.59);
  --vui-color-surface-container-low: oklch(22.5% 0.009 255.61);
  --vui-color-surface-container-highest: oklch(32.89% 0.0092 268.38);

  color-scheme: dark;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

body {
  font-family: var(--vui-font-family-sans-serif);
  font-size: var(--vui-font-size-base);
  line-height: var(--vui-line-height-base);
  font-weight: var(--vui-font-weight-regular);
  text-align: start;
  background-color: var(--vui-color-surface);
}

body[data-theme="dark"] {
  color-scheme: dark only;
}

body[data-theme="light"] {
  color-scheme: light only;
}
