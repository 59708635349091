html {
  scrollbar-width: thin;
  scrollbar-color: #b7b7b7 transparent;
}

body {
  -webkit-touch-callout: none;
}

::-webkit-scrollbar {
  width: 4;
  height: 4;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
